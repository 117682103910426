<template>
  <div>
    <BankIDQrCodeComponent />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BankIDQrCodeComponent from '@/view/components/bankid/BankIDQrCodeComponent.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'TestBankIDPage',
  components: {
    BankIDQrCodeComponent
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'periods', 'companies', 'user']),
  },
  async mounted() {
    
  },
  data() {
    return {

    };
  },
  watch: {
    
  },
  methods: {
    
  }
};
</script>
